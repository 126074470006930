/**
 * hardware shop store
 */


function cleanCart(state, item) {
  if (item.id in state.cart && state.cart[item.id] <= 0)
    delete state.cart[item.id];
};


export default {
  namespaced: true,
  state: {
    loaded: false,
    items: [],
    cart: {},
    reference: null,
  },
  mutations: {
    setLoaded(state, value) {
      state.loaded = value;
    },
    setItems(state, items) {
      state.items = [...items];
    },
    plusCart(state, item) {
      state.cart = {
        ...state.cart,
        [item.id]: (state.cart[item.id] || 0) + 1
      };
    },
    minusCart(state, item) {
      state.cart = {
        ...state.cart,
        [item.id]: (state.cart[item.id] || 0) - 1
      };
      cleanCart(state, item);
    },
    removeCart(state, item) {
      state.cart = {
        ...state.cart,
        [item.id]: 0
      };
      cleanCart(state, item);
    },
    setCart(state, item) {
      state.cart = {
        ...state.cart,
        [item.id]: Number(item.quantity || 0)
      };
      cleanCart(state, item);
    },
    setReference(state, value) {
      state.reference = value;
    },
    resetCart(state) {
      state.cart = {};
    },
    resetItems(state) {
      state.items = [];
    },
  },
  getters: {
    /* get number of items in cart */
    itemsInCart: (state, getters) => {
      return Object.values(state.cart).reduce(function (a, b) {
        return a + b;
      }, 0);
    },
    /* get highest shipping price item */
    shippingItem:(state, getters) => {
      var shippingPrices = [];
      state.items.forEach(function(item) {
        if (item.id in state.cart && state.cart[item.id] > 0)
          shippingPrices = shippingPrices.concat(item.shipping_prices);
      });
      shippingPrices.sort(function(a, b) {
        return b.price_fix - a.price_fix;
      });
      if (shippingPrices.length > 0) return shippingPrices[0];
      return null;
    },
    /* get highest price item based on graduated_prices */
    getPriceItem: (state, getters) => (item) => {
      if (item.hasOwnProperty("graduated_prices")) {
          var quantity = item.id in state.cart ? state.cart[item.id] : 0;
          var items = item.graduated_prices.filter(function (i) {
              return (
                  i.qty_min <= quantity && (i.qty_max === 0 || quantity <= i.qty_max)
              );
          });
          if (items.length > 0) return items[0];
      }
      return item;
    },
    getItemPrices: (state, getters) => (item) => {
      var prices = [{ ...item }];
      if (item.hasOwnProperty("graduated_prices")) {
          prices = prices.concat(
              item.graduated_prices.map(function (i) {
                  return { ...i };
              })
          );
      }
      return prices;
    },
    /* get extended cart list */
    cartItems: (state, getters) => {
      return state.items.filter(function (item) {
        return item.id in state.cart;
      }).map(function (item) {
        var priceItem = getters.getPriceItem(item);
        return {
          ...item,
          quantity: state.cart[item.id] || 0,
          // number: state.cart[item.id] || 0,
          priceItem: priceItem,
          name: priceItem.name || item.name,
          title: priceItem.title || item.title,
          price_recurring: priceItem.price_recurring,
          price_fix: priceItem.price_fix,
          qty_min: priceItem.qty_min,
          qty_max: priceItem.qty_max,
        };
      })
    },
    /* total monthly price */
    priceMonthly: (state, getters) => {
      return getters.cartItems.map(function (item) {
        return (item.price_recurring || 0) * item.quantity;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
    },
    /* total fix price */
    priceFix: (state, getters) => {
      return getters.cartItems.map(function (item) {
        return (item.price_fix || 0) * item.quantity;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
    },
    /* get false if max number for this item has been reached */
    maxPlus: (state, getters) => (item) => {
      return (
        item.qty_max != null &&
          item.qty_max != 0 &&
          item.id in state.cart &&
          state.cart[item.id] >= item.qty_max &&
          (item.graduated_prices == null || item.graduated_prices.length == 0)
      );
    },
    /* get false if min number for this item has been reached */
    minMinus:(state, getters) => (item) => {
      return (item.id in state.cart && state.cart[item.id] <= 0);
    },
  },
  actions: {
    reset({ commit, state }) {
      commit("resetCart");
      commit("resetItems");
      commit("setLoaded", false);
      commit("setReference", null);
    },
    load({ commit, state }, items) {
      commit("setItems", items);
      commit("setLoaded", true);
    }
  }
};
