export default {
  title: "Login Portal",
  copyright: "iWay AG",
  copyrightStart: 2017,
  i18nLocale: "en",
  i18nFallbackLocale: "en",
  backendTimeout: 300000,
  timezone: "Europe/Zurich",
  favicon: process.env.BASE_URL + "favicon.ico",
  sentryDsn: "https://a11da4c935494acbbcc31c684e68c258@sentry-new.iway.ch/2",
  sentryDebugLevel: "warning",
  sentryEnable: true,
  versionCheckFrequency: 1000 * 60 * 2,
  gfoApiUrl: "https://gfo.iway.ch/api",
  gfoApiTokenEnd: "103f64962cc811ec95b4005056b197f8",
  gfoApiTokenPar: "924df94b2fc611ec95b4005056b197f8",
  gfoApiTokenWho: "a3bb0b04370911ec95b4005056b197f8",
  // new VueJS 3
  gfoCheckerSrc: "https://gfo-checker2.iway.ch/lic/gfo-checker-2.js",
  // old VueJS 2
  // gfoCheckerSrc: "https://gfo-apps.iway.ch/gfo_checker/lic/app.js",
  addressBackendUrl: "https://addr-api.iway.ch/",
  addressBackendToken: "da39bcc694150bbf2fdef099b201d15ccbb083a1",
  addressBackendTimeout: 3000,
  billingTypes: [],
};
