import store from "@/store";

export default function (route) {
  // allow if permissions is not defined
  if (route.meta == null) {
    return true;
  }

  var permissions =
    route.meta.permissions == null ? null : route.meta.permissions;
  var tagAny =
    route.meta.permissionsTagAny == null ? true : route.meta.permissionsTagAny;
  var roleAny =
    route.meta.permissionsRoleAny == null
      ? true
      : route.meta.permissionsRoleAny;
  var mobileSpecial =
    permissions && permissions.mobileSpecial != null
      ? permissions.mobileSpecial
      : false;

  // allow if permissions is not defined
  if (permissions == null) {
    // console.log(">>> no permissions defined: OK");
    return true;
  }

  // reject if session is not ready
  if (!store.getters.isReady) {
    // console.log(">>> not ready: NOK");
    return false;
  }

  // reject if roles not match
  if (roleAny && permissions.roles != null) {
    if (
      permissions.roles.filter(function (role) {
        return store.state.session.apiRoles.includes(role);
      }).length == 0
    ) {
      // console.log(">>> invalid roles (any): NOK");
      return false;
    }
  } else if (permissions.roles != null) {
    if (
      permissions.roles.filter(function (role) {
        return store.state.session.apiRoles.includes(role);
      }).length != permissions.roles.length
    ) {
      // console.log(">>> invalid roles (all): NOK");
      return false;
    }
  }

  // reject if tags not match
  if (tagAny && permissions.tags != null) {
    if (
      permissions.tags.filter(function (tag) {
        return store.state.session.tags.includes(tag);
      }).length == 0
    ) {
      // console.log(">>> invalid tags: NOK");
      return false;
    }
  } else if (permissions.tags != null) {
    if (
      permissions.tags.filter(function (tag) {
        return store.state.session.tags.includes(tag);
      }).length != permissions.tags.length
    ) {
      // console.log(">>> invalid tags: NOK");
      return false;
    }
  }

  // reject if permissions not match
  if (
    permissions.permissions != null &&
    permissions.permissions.filter(function (permission) {
      return store.state.session.permission == permission;
    }).length == 0
  ) {
    // console.log(">>> invalid permissions: NOK");
    return false;
  }

  // reject if partnerType not match
  if (
    permissions.partnerTypes != null &&
    permissions.partnerTypes.filter(function (partnerType) {
      return store.state.session.partnerType == partnerType;
    }).length == 0
  ) {
    // console.log(">>> invalid partnerType: NOK");
    return false;
  }

  // reject if customerNumbers not match
  if (
    permissions.customerNumbers != null &&
    permissions.customerNumbers.filter(function (number) {
      return store.state.session.number == number;
    }).length == 0
  ) {
    // console.log(">>> invalid customer number: NOK");
    return false;
  }

  // reject if isStaff not match
  if (
    permissions.isStaff != null &&
    permissions.isStaff != store.getters.isStaff
  ) {
    // console.log(">>> no staff: NOK");
    return false;
  }

  // reject if isPerson not match
  if (
    permissions.isPerson != null &&
    permissions.isPerson != store.getters.isPerson
  ) {
    // console.log(">>> no person: NOK");
    return false;
  }

  // reject if memberOfStaffGroup not match
  if (
    permissions.memberOfStaffGroup != null &&
    !store.getters.staffGroups.includes(permissions.memberOfStaffGroup)
  ) {
    // console.log(">>> memberOfStaffGroup: NOK");
    return false;
  }

  if (mobileSpecial) {
    if (
      store.state.session.tags.includes("psuite-tenant").length > 0 ||
      !(
        store.getters.isStaff ||
        store.state.session.partnerType == "WHO" ||
        store.state.session.partnerType == "END"
      )
    )
      return false;
  }

  return true;
}
