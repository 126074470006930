<template>
  <v-app id="login-app" ref="app" :style="appStyle">
    <vue-snotify />

    <v-navigation-drawer
      v-if="renderNavigation && $store.getters.isReady"
      v-model="drawer"
      app
      class="d-print-none"
    >
      <v-list>
        <v-list-item
          active-class="primary primatyText--text"
          two-line
          key="title"
        >
          <v-list-item-avatar>
            <img v-auth-image="avatarUrl" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-light">
              {{ $store.state.session.customerName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              #{{ $store.state.session.number }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="$store.state.session.isPerson">
              {{
                $store.state.session.personName ||
                $store.state.session.personEmail
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <!-- main menu -->
      <v-list>
        <template v-for="(route, index) in mainMenu">
          <!-- main menu with sub entries -->
          <template v-if="subMenu(route).length > 0 && hasPermissions(route)">
            <v-list-group
              :key="index"
              active-class="primary primaryText--text"
              :group="route.name"
              :inactive="!hasPermissions(route)"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon
                    v-text="route.meta.icon"
                    :color="isStaffOnly(route) ? 'staff' : ''"
                  >
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$t(route.meta.titleShort || route.meta.title)"
                    :class="{
                      'staff--text': isStaffOnly(route),
                    }"
                  >
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-for="(subRoute, sindex) in subMenu(route)">
                <v-list-item
                  active-class="primary primaryText--text"
                  v-show="hasPermissions(subRoute)"
                  :key="index + '.' + sindex"
                  :to="{ name: subRoute.name }"
                  :inactive="!hasPermissions(subRoute)"
                  link
                  dense
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="
                        $t(subRoute.meta.titleShort || subRoute.meta.title)
                      "
                      :class="{
                        'staff--text': isStaffOnly(subRoute),
                      }"
                    >
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      v-text="subRoute.meta.icon"
                      :color="isStaffOnly(subRoute) ? 'staff' : ''"
                    />
                  </v-list-item-icon>
                </v-list-item>
                <v-divider
                  v-if="sindex < subMenu(route).length - 1"
                  :key="index + '.' + sindex + '.divider'"
                />
              </template>
            </v-list-group>
            <v-divider
              v-if="index < mainMenu.length - 1"
              :key="index + '.divider'"
            />
          </template>

          <!-- main menu without sub entries-->
          <template
            v-else-if="
              subMenu(route, true).length == 0 && hasPermissions(route)
            "
          >
            <v-list-item
              :key="index"
              active-class="primary primaryText--text"
              :to="{ name: route.name }"
              :inactive="!hasPermissions(route)"
              exact
              link
            >
              <v-list-item-icon>
                <v-icon :color="isStaffOnly(route) ? 'staff' : ''">
                  {{ route.meta.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{
                    'staff--text': isStaffOnly(route),
                  }"
                >
                  {{ $t(route.meta.titleShort || route.meta.title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index + '.divider'" />
          </template>
        </template>
      </v-list>

      <template v-slot:append>
        <app-footer />
      </template>
    </v-navigation-drawer>

    <v-app-bar app dark class="titleBar d-print-none">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="$store.getters.isReady"
      />

      <v-toolbar-title>
        {{ $t(this.$route.meta.title) }}
      </v-toolbar-title>

      <v-spacer />

      <v-toolbar-title v-if="$store.getters.isReady">
        <v-hover v-if="$store.getters.isStaff" v-slot:default="{ hover }">
          <v-btn text :to="{ name: 'staff-account-switch' }" color="staff">
            <v-icon>mdi-account-switch</v-icon>
            <v-expand-x-transition>
              <span v-show="hover" class="ml-2">
                {{ $t("customer switch") }}
              </span>
            </v-expand-x-transition>
          </v-btn>
        </v-hover>

        <v-hover v-if="$store.getters.isReadOnly" v-slot:default="{ hover }">
          <v-btn text>
            <v-icon color="warning">mdi-account-lock</v-icon>
            <v-expand-x-transition>
              <span v-show="hover" class="ml-2">
                {{ $t("account locked") }}
              </span>
            </v-expand-x-transition>
          </v-btn>
        </v-hover>
      </v-toolbar-title>

      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            active-class="primary primaryText--text"
            v-if="$store.getters.isReady"
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
          <v-divider key="logout" />

          <v-list-item
            @click="setSchema('auto')"
            :disabled="currentSchema == 'auto'"
          >
            <v-list-item-icon>
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("auto theme") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider key="auto-mode" />

          <v-list-item
            @click="setSchema('light')"
            :disabled="currentSchema == 'light'"
          >
            <v-list-item-icon>
              <v-icon>mdi-white-balance-sunny</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("light theme") }}
            </v-list-item-title>
          </v-list-item>
          <v-divider key="light-mode" />

          <v-list-item
            @click="setSchema('dark')"
            :disabled="currentSchema == 'dark'"
          >
            <v-list-item-icon>
              <v-icon>mdi-moon-waning-crescent</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("dark theme") }}
            </v-list-item-title>
          </v-list-item>
          <v-divider key="dark-mode" />

          <template v-for="(lang, index) in languages">
            <v-list-item
              :key="index + '.lang'"
              link
              @click="setLocale(lang.code)"
              v-bind:disabled="$store.state.locale === lang.code"
            >
              <v-list-item-icon>
                <lang-flag :iso="lang.code" :squared="false" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t(lang.title) }}</v-list-item-title>
            </v-list-item>
            <v-divider
              v-if="index < languages.length - 1"
              :key="index + '.lang-menu'"
            />
          </template>

          <template v-for="(route, index) in barMenu">
            <v-list-item
              active-class="v-list-item--disabled"
              :key="index"
              @click="() => {}"
            >
              <v-list-item-icon>
                <v-icon :color="isStaffOnly(route) ? 'staff' : ''">
                  {{ route.meta.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title
                :class="{
                  'staff--text': isStaffOnly(route),
                }"
              >
                {{ $t(route.meta.titleShort || route.meta.title) }}
              </v-list-item-title>
            </v-list-item>
            <v-divider
              v-if="index < barMenu.length - 1"
              :key="index + '.bar-menu'"
            />
          </template>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <keep-alive v-if="renderContent" :include="keepAliveComponents">
          <router-view />
        </keep-alive>
      </v-container>
    </v-main>

    <system-error />
  </v-app>
</template>

<script>
import loadLocaleVeeValidate from "@/plugins/validate";
import AppFooter from "@/components/layout/AppFooter";
import SystemError from "@/components/layout/SystemError";
import checkPermissions from "@/router/permissions";
import reloadSite from "@/utils/mixins/reloadSite";
import checkVersion from "@/utils/mixins/checkVersion";
import { setGfoDynamicRoutes } from "@/router/gfoDynamicRoutes";

import { routes } from "@/router";

export default {
  name: "App",
  metaInfo() {
    return {
      title: this.appTitle,
      lang: this.$store.state.locale,
      link: [{ rel: "icon", href: this.$config.favicon }],
    };
  },
  components: {
    AppFooter,
    SystemError,
  },
  mixins: [reloadSite, checkVersion],
  data: () => ({
    renderCounter: 0,
    renderNavigation: true,
    renderContent: true,
    drawer: null,
    keepAliveComponents: [],
    languages: [
      { code: "en", title: "english" },
      { code: "de", title: "german" },
    ],
    chunkLoadError: false,
    currentSchema: null,
    redirect_uri: null,
  }),
  computed: {
    appStyle() {
      if (
        this.$config.backgroundImage != null &&
        (this.$route.meta == null || this.$route.meta.showBackground)
      )
        return {
          backgroundImage: "url(" + this.$config.backgroundImage + ")",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          ["-webkitBackgroundSize"]: "cover",
          ["-mozBackgroundSize"]: "cover",
          ["-oBackgroundSize"]: "cover",
        };
      return {};
    },
    appTitle() {
      return this.$config.title + " - " + this.$t(this.$route.meta.title);
    },
    mainMenu() {
      return routes.filter(
        (route) => route.meta != null && route.meta.scope == "main"
      );
    },
    barMenu() {
      return routes.filter(
        (route) => route.meta != null && route.meta.scope == "app"
      );
    },
    avatarUrl() {
      if (this.$store.state.session.id)
        return (
          this.$config.backendUrl +
          "customers/" +
          this.$store.state.session.id +
          "/logo?avatar=true"
        );
    },
  },
  watch: {
    versionChanged(value) {
      if (value)
        this.$snotify.confirm(
          this.$i18n.t("newVersion", { version: this.checkedVersion }),
          this.$i18n.t("new version"),
          {
            timeout: 360000,
            showProgressBar: false,
            closeOnClick: false,
            buttons: [
              {
                text: this.$i18n.t("later"),
                action: (toast) => {
                  this.$snotify.remove(toast.id);
                },
                bold: true,
              },
              {
                text: this.$i18n.t("reload"),
                action: (toast) => {
                  this.$snotify.remove(toast.id);
                  this.reloadSite();
                },
                bold: true,
              },
              {
                text: this.$i18n.t("relogin"),
                action: (toast) => {
                  this.$snotify.remove(toast.id);
                  this.logout();
                },
                bold: true,
              },
            ],
          }
        );
    },
    chunkLoadError(value) {
      if (value) {
        this.chunkLoadError = false;
        this.$snotify.confirm(
          this.$i18n.t("chunkLoadError"),
          this.$i18n.t("internal error"),
          {
            timeout: 360000,
            showProgressBar: false,
            closeOnClick: false,
            buttons: [
              {
                text: this.$i18n.t("later"),
                action: (toast) => {
                  this.$snotify.remove(toast.id);
                },
                bold: true,
              },
              {
                text: this.$i18n.t("reload"),
                action: (toast) => {
                  this.$snotify.remove(toast.id);
                  this.reloadSite();
                },
                bold: true,
              },
              {
                text: this.$i18n.t("relogin"),
                action: (toast) => {
                  this.$snotify.remove(toast.id);
                  this.logout();
                },
                bold: true,
              },
            ],
          }
        );
      }
    },
  },
  methods: {
    isStaffOnly(route) {
      return (
        route.meta != null &&
        route.meta.permissions != null &&
        route.meta.permissions.isStaff == true
      );
    },
    forceRerender(content, navigation) {
      /* seems not working as expected :-/ */
      this.renderCounter += 1;
      this.renderContent = content != null ? Boolean(content) : false;
      this.renderNavigation = navigation != null ? Boolean(navigation) : false;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.renderContent = true;
        this.renderNavigation = true;
      });
    },
    buildKeepAliveComponents(route) {
      var that = this;
      if (route.meta != null && route.meta.keepAlive) {
        route.component().then(function (component) {
          that.keepAliveComponents.push(component.default.name);
        });
      }
      if (route.hasOwnProperty("children")) {
        route.children.forEach(function (childRoute) {
          that.buildKeepAliveComponents(childRoute);
        });
      }
    },
    logout() {
      var that = this;
      this.$http
        .post("logout")
        .then(function (response) {
          that.redirect_uri = response.data.redirect_uri || null;
        })
        .catch(function (err) {
          console.log(">>> backend logout error: ", err);
        })
        .finally(function () {
          that.$store.commit("resetSession");
          if (that.redirect_uri != null) {
            console.log(
              "backend logout done: redirect after logout to ",
              that.redirect_uri
            );
            window.location.href = that.redirect_uri;
          } else that.reloadSite();
        });
    },
    subMenu(route, ignorePerms) {
      ignorePerms = ignorePerms || false;
      if (route.children == null) {
        return [];
      } else {
        return route.children.filter(
          (route) =>
            route.meta != null &&
            route.meta.scope == "main" &&
            (ignorePerms || this.hasPermissions(route))
        );
      }
    },
    setLocale(code) {
      this.$store.commit("setLocale", code); // local
      this.$vuetify.lang.current = code; // Vuetify
      this.$root.$i18n.locale = code; // Vue-i18n
      loadLocaleVeeValidate(code); // VeeValidate
    },
    setSchema(schema) {
      this.currentSchema = schema;
      if (this.currentSchema == "auto") {
        this.$vuetify.theme.dark = window.matchMedia(
          "(prefers-color-scheme:dark)"
        ).matches;
        localStorage.setItem("darkMode", schema);
      } else if (this.currentSchema == "dark") {
        this.$vuetify.theme.dark = true;
        localStorage.setItem("darkMode", "true");
      } else {
        this.$vuetify.theme.dark = false;
        this.currentSchema = "light";
        localStorage.setItem("darkMode", "false");
      }
    },
    async checkAutoSchema() {
      var isDark = window.matchMedia("(prefers-color-scheme:dark)").matches;
      if (this.currentSchema == "auto") this.$vuetify.theme.dark = isDark;
    },
    initCheckAutoSchema() {
      setInterval(() => {
        this.checkAutoSchema();
      }, Number(1000 * 60 * 2));
    },
    hasPermissions(route) {
      return checkPermissions(route);
    },
  },
  created: async function() {
    var that = this;
    /* fill keepAliveComponents for component caching (see template <keep-alive /> */
    routes.forEach(function (route) {
      that.buildKeepAliveComponents(route);
    });
    this.currentSchema =
      localStorage.getItem("darkMode", "false") == "auto"
        ? "auto"
        : localStorage.getItem("darkMode", "false") == "true"
        ? "dark"
        : "light";
  },
  mounted:async function(){
    this.initVersionCheck();
    this.initCheckAutoSchema();
    await setGfoDynamicRoutes(this.$config);
  },
};
</script>

<i18n>
{
  "en": {
    "customer switch": "Customer switch",
    "logout": "Logout",
    "language": "Language",
    "dark theme": "Dark theme",
    "light theme": "Light theme",
    "auto theme": "Auto theme",
    "account locked": "account locked",
    "new version": "New Version",
    "newVersion": "Portal Version {version} has been released, please reload site or relogin asap.!",
    "later": "Later",
    "reload": "Reload",
    "relogin": "Relogin",
    "chunkLoadError": "An internal error occurred. A new portal version has probably been made available, please reload the page or log in again immediately! ",
    "internal error": "Internal Error"
  },
  "de": {
    "customer switch": "Kunden wechseln",
    "logout": "Abmelden",
    "language": "Sprache",
    "dark theme": "Dark Mode",
    "light theme": "Light Mode",
    "auto theme": "Auto Mode",
    "account locked": "Zugang gesperrt",
    "new version": "Neue Version",
    "newVersion": "Portal Version {version} wurde bereit gestellt, bitte laden Sie die Seite neu oder melden sich umgehend neu an!",
    "later": "später",
    "reload": "Neu laden",
    "relogin": "Neu anmeden",
    "chunkLoadError": "Ein interner Fehler ist aufgetreten. Wahrscheinlich wurde eine neue Portal Version bereit gestellt, bitte laden Sie die Seite neu oder melden sich umgehend neu an!",
    "internal error": "Interner Fehler"
  }
}
</i18n>

<style>
.monospace {
  font-family: "Roboto Mono", monospace;
}
.renderNewlines {
  white-space: pre-wrap;
}
/* append asterisk to required input labels */
.required label:after {
  color: #e32;
  content: " *";
  display: inline;
}
@media print {
  .v-main {
    padding: 0 !important;
  }
}
</style>
