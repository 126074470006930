export default {
  title: "iWay AG Login Portal",
  copyright: "iWay AG",
  favicon: process.env.BASE_URL + "iway-favicon.ico",
  mobileTransferFormWho:
    process.env.BASE_URL + "iWay-Portierungsformular-Mobile-FORM-WHO.pdf",
  mobileTransferForm:
    process.env.BASE_URL + "iWay-Portierungsformular-Mobile-FORM.pdf",
  mobileZonesUrl: "https://www.iway.ch/mobile/zonen-und-tarife/",
  voipTransferForm:
    process.env.BASE_URL + "iWay_Portierungsformular_Festnetz.pdf",
  voipTransferFormDdi:
    process.env.BASE_URL + "iWay_Portierungsformular_DDI.pdf",
  mySportsAGB: process.env.BASE_URL + "MySportsAGB_07.2022_DE.pdf",
  netbackupDownloadImg: process.env.BASE_URL + "NetbackupDownload.png",
  logo: require("@/assets/iWay-logo-white.svg"),
  msgChangePermissionGroup: "loginiwaych_msg_access",
  frontendUrl: "https://login.iway.ch",
  backendUrl: "https://backend.login.iway.ch/api/",
  billingTypes: [
    {
      id: 0,
      title_de: "ESR",
      title_en: "Payment slip by post",
      visible: true,
      disabled: false,
    },
    {
      id: 1,
      title_de: "Kreditkarte",
      title_en: "Credit card",
      visible: false,
      disabled: false,
    },
    {
      id: 3,
      title_de: "Rechnung ohne Einzahlungsschein",
      title_en: "Post without payment slip",
      visible: false,
      disabled: false,
    },
    {
      id: 4,
      title_de: "Mail",
      title_en: "Email",
      visible: true,
      disabled: false,
    },
    {
      id: 5,
      title_de: "Kreditkarte, Bestätigung per Mail",
      title_en: "Credit card, confirmation by email",
      visible: true,
      disabled: true,
    },
    {
      id: 6,
      title_de: "ESR und Mail",
      title_en: "Payment slip and email",
      visible: false,
      disabled: false,
    },
    {
      id: 9,
      title_de: "LSV+, Bestätigung per Brief",
      title_en: "LSV+, confirmation by post",
      visible: true,
      disabled: true,
    },
    {
      id: 10,
      title_de: "LSV+, Bestätigung per Mail",
      title_en: "LSV+, confirmation by email",
      visible: true,
      disabled: true,
    },
    {
      id: 11,
      title_de: "eBill, Beleg per Mail",
      title_en: "Paynet, receipt by email",
      note_de: "im E-Banking unter E-Bill Rechnungssteller iWay AG hinzufügen",
      note_en: "add biller iWay AG in e-banking under e-bill",
      visible: true,
      disabled: true,
    },
    {
      id: 12,
      title_de: "eBill, kein Beleg per Mail",
      title_en: "eBill, without receipt my email",
      note_de: "im E-Banking unter E-Bill Rechnungssteller iWay AG hinzufügen",
      note_en: "add biller iWay AG in e-banking under e-bill",
      visible: true,
      disabled: true,
    },
    {
      id: 13,
      title_de: "DebitDirect per Mail",
      title_en: "Debit direct by email",
      visible: false,
      disabled: false,
    },
    {
      id: 19,
      title_de: "DTAUS, Bestätigung per Brief",
      title_en: "DTAUS, confirmation by post",
      visible: false,
      disabled: false,
    },
    {
      id: 20,
      title_de: "DTAUS, Bestätigung per Mail",
      title_en: "DTAUS, confirmation by email",
      visible: false,
      disabled: false,
    },
  ],
};
