import Vue from 'vue';
import i18n from "@/plugins/i18n";
import store from "@/store";
import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize,
    configure
} from 'vee-validate/dist/vee-validate.full.esm';
import {
    parsePhoneNumber,
    parsePhoneNumberFromString,
    parseDigits,
    getType
} from 'libphonenumber-js/max';



const punycode = require('punycode/');
const ip = require("@/utils/ip");
const moment = require("moment");
const email = /^[a-zA-Z0-9\-_\.&+]*[a-zA-Z0-9_]@(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z\-0-9]{2,}$/;
const dns = /^[a-zA-Z0-9\-_\.&+\$\@\#]+$/;
const nsn = /^0[1-9]\d{8}$/;
const stbserial = /^\b[A-Za-z0-9]{6}[0-9]{10,12}\b/;
const key3cx = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
const locRecord = /^\d{1,2} (\d{1,2} (\d{1,2}(\.?\d{1,3})? )?)?[NS] \d{1,3} (\d{1,2} (\d{1,2}(\.?\d{1,3})? )?)?[EW] -?\d{1,8}\.?\d{2}m?( \d{1,8}(\.\d{1,2})?m?( \d{1,8}(\.\d{1,2})?m?( \d{1,8}(\.\d{1,2})?m?)?)?)?$/;

// returns `true` if value is right nameserver entry
// <dns name>[SPACE<ip address>]
function isValidNameserver(value, punyDecode=false) {
    if (value != null && (typeof value === 'string' || value instanceof String)) {
        var parts = value.split(' ');
        if (parts.length == 2) {
            var ns = parts[0];
            if (punyDecode) ns = punycode.toASCII(ns);
            var ipaddr = parts[1];
            return dns.test(ns) && ip.parse(ipaddr) != null;
        } else if (parts.length == 1) {
            var ns = parts[0];
            if (punyDecode) ns = punycode.toASCII(ns);
            return dns.test(ns);
        }
    }
    return false;
}

function isValidatePhoneNumberRange(phoneNumber1, phoneNumber2, countryCode, maxNumbers) {
    if (phoneNumber1 && phoneNumber2) {
        try {
            countryCode = countryCode || 'CH';
            const num1 = Number(parseDigits(parsePhoneNumberFromString(phoneNumber1, countryCode).formatNational()));
            const num2 = Number(parseDigits(parsePhoneNumberFromString(phoneNumber2, countryCode).formatNational()));
            var start = num1 < num2 ? num1 : num2;
            var end = num1 <= num2 ? num2 : num1;
            if (maxNumbers != null)
                return Boolean(end - start <= maxNumbers)
            else return true
        } catch (err) {
            return false;
        }
    }
    return true;
}

function isValidPartOfPhoneNumberBlock(phoneNumberMain, phoneNumberBlock1, phoneNumberBlock2, countryCode) {
    if (phoneNumberMain && phoneNumberBlock1 && phoneNumberBlock2) {
        try {
            countryCode = countryCode || 'CH';
            const num0 = Number(parseDigits(parsePhoneNumberFromString(phoneNumberMain, countryCode).formatNational()));
            const num1 = Number(parseDigits(parsePhoneNumberFromString(phoneNumberBlock1, countryCode).formatNational()));
            const num2 = Number(parseDigits(parsePhoneNumberFromString(phoneNumberBlock2, countryCode).formatNational()));
            const start = num1 < num2 ? num1 : num2;
            const end = num1 <= num2 ? num2 : num1;
            const mainStr = String(num0);
            const startStr = String(start);
            const endStr = String(end);
            if (num0 >= start && num0 <= end)
                return true
            else if (startStr.indexOf(mainStr) == 0 && endStr.indexOf(mainStr) == 0)
                return true
            else return false
        } catch (err) {
            return false;
        }
    }
    return true;
}

// value is valid IP address
extend("ip", {
    validate(value) {
        return value && ip.parse(value) !== null
    },
    message: (_, values) => i18n.t("invalid IP address", values)
});

// value is valid IP address list
extend("ip_list", {
    validate(value) {
        if (!Array.isArray(value))
            return false;
        return value.filter(function (e) {
            return e && ip.parse(e) !== null;
        }).length == value.length;
    },
    message: (_, values) => i18n.t("invalid IP address list", values)
});

// value is valid IPv4 address
extend("ipv4", {
    validate(value) {
        return value && ip.isIpv4(value)
    },
    message: (_, values) => i18n.t("invalid IPv4 address", values)
});

extend("ipv4_list", {
    validate(value) {
        if (!Array.isArray(value))
            return false;
        return value.filter(function (e) {
            return e && ip.isIpv4(e) !== null;
        }).length == value.length;
    },
    message: (_, values) => i18n.t("invalid IPv4 address list", values)
});

// value is valid IPv6 address
extend("ipv6", {
    validate(value) {
        return value && ip.isIpv6(value)
    },
    message: (_, values) => i18n.t("invalid IPv6 address", values)
});

extend("ipv6_list", {
    validate(value) {
        if (!Array.isArray(value))
            return false;
        return value.filter(function (e) {
            return e && ip.isIpv6(e) !== null;
        }).length == value.length;
    },
    message: (_, values) => i18n.t("invalid IPv6 address list", values)
});

// value is part of network
extend("partOfIpNet", {
    validate(value, { network }) {
        var cidr = ip.createCIDR(network);
        var addr = ip.parse(value);
        return addr !== null && cidr.contains(addr.toString());
    },
    params: ['network'],
    message: (_, values) => i18n.t("IP is not part of {network}", values)
});

// value is valid nameserver name
extend("nameserver", {
    validate(value) {
        return isValidNameserver(value);
    },
    message: (_, values) => i18n.t("invalid nameserver", values)
});

// value is valid DNS names list
extend("nameserver_list", {
    validate(value) {
        if (!Array.isArray(value))
            return false;
        return value.filter(function (e) {
            return e && isValidNameserver(e);
        }).length == value.length;
    },
    message: (_, values) => i18n.t("invalid nameserver list", values)
});

// value is valid DNS name
extend("dns", {
    validate(value) {
        return dns.test(String(value));
    },
    message: (_, values) => i18n.t("invalid DNS name", values)
});

// value is valid DNS names list
extend("dns_list", {
    validate(value) {
        if (!Array.isArray(value))
            return false;
        return value.filter(function (e) {
            return e && dns.test(String(e));
        }).length == value.length;
    },
    message: (_, values) => i18n.t("invalid DNS names list", values)
});

// value is email (https://docs.iway.ch/display/ENG/Email+Validation)
extend("email", {
    validate(value) {
        return email.test(String(value));
    },
    message: (_, values) => i18n.t("invalid email", values)
});

// value is email or list of emails
extend("email_list", {
    validate(value) {
        value = String(value)
            .split(/[\,\;\s/]+/)
            .map(function (emailStr) {
                return emailStr.trim();
            });
        return value.every(function (val) { return email.test(String(val)); });
    },
    message: (_, values) => i18n.t("invalid email or list of emails", values)
});

// rules="target_max:field_name,100"
extend('target_max', {
    params: ['target', 'max'],
    validate(value, { target, max }) {
        return target ? target.length <= Number(max) : true;
    },
    message: (_, values) => i18n.t("The {_field_} field may not be greater than {max} characters", values)
});

/* value is valid phone number */
extend("phone", {
    params: ['countryCode', 'isValid'],
    validate(value, { countryCode, isValid }) {
        const country = countryCode || 'CH';
        const valid = isValid == "false" ? false : true;
        try {
            const phoneNumber = parsePhoneNumberFromString(value, country);
            var result = phoneNumber != null;
            if (valid)
                result = result && phoneNumber && phoneNumber.isValid();
            if (countryCode != null)
                result = result && phoneNumber && phoneNumber.country == country;
            return result;
        } catch (error) {
            return false;
        }
    },
    message: (_, values) => values.countryCode == null
        ? i18n.t("invalid phone number", values)
        : i18n.t("invalid phone number or country is not {countryCode}", values)
});

/* value is valid phone number wit specific type

known types:

* MOBILE — Cellphones.
* FIXED_LINE — Stationary phones.
* FIXED_LINE_OR_MOBILE — Could be MOBILE or FIXED_LINE.
* PREMIUM_RATE — Callers are charged by call or per minute. SMS text messages are also subject to charge.
* TOLL_FREE — Free to call from anywhere.
* SHARED_COST — "An intermediate level of telephone call billing where the charge for calling a particular international or long-distance phone number is partially, but not entirely, paid for by the recipient".
* VOIP — "IP telephony". Calls are made over the Internet rather than via the conventional telephone-only lines.
* PERSONAL_NUMBER — Phones connected by satellites.
* PAGER — "Pagers" are wireless telecommunications devices that were widely used in the 80-es and could receive (and, optionally, send) text or voice messages.
* UAN — "UAN is a number resource that allows a service or business with several terminating lines to be reached through a unique universal number. A UAN number shall be dialable from the entire Pakistan, based on the applicant’s proposed coverage without dialing the area code. UAN cannot be assigned to two separate business or mutually exclusive public services. Each service provider who allows UAN through its network shall offer a tariff, which is not more expensive than the normal tariff available for a similar non-UAN public service".
* VOICEMAIL — "A voicem

*/
extend("phone_type", {
    params: ['countryCode', 'typeList'],
    validate(value, { countryCode, typeList }) {
        const country = countryCode || 'CH';
        const types = (typeList || '').split('+').map(s => s.toUpperCase());
        try {
            const phoneNumber = parsePhoneNumberFromString(value, country);
            var result = phoneNumber != null;
            if (types.length > 0)
                result = result && types.includes(phoneNumber.getType());
            return result;
        } catch (error) {
            return false;
        }
    },
    message: (_, values) => i18n.t("invalid phone number type", values)
});

// value is valid phone number block
extend("phone_block", {
    params: ['other', 'countryCode', 'maxNumbers'],
    validate(value, { other, countryCode, maxNumbers }) {
        return isValidatePhoneNumberRange(value, other, countryCode, maxNumbers)
    },
    message: (_, values) => i18n.t("phone block is to large (only {maxNumbers} allowed)", values)   
});

// value is valid phone number block
extend("phone_block_main", {
    params: ['blockStart', 'blockEnd', 'countryCode'],

    validate(value, { blockStart, blockEnd, countryCode }) {
        return isValidPartOfPhoneNumberBlock(value, blockStart, blockEnd, countryCode)
    },
    message: (_, values) => i18n.t("invalid main number of phone block", values) 
});

// fake max validation
extend("fake_max", {
    params: ['current', 'max', 'msg'],
    validate(value, { current, max }) {
        return Number(current) <= Number(max);
    },
    message: (_, values) => values.msg 
});


// value is valid tv set-top box serial
extend("stbserial", {
    validate(value) {
        return stbserial.test(String(value))
    },
    message: (_, values) => i18n.t("invalid TV set-top box", values)
});

// value is valid NSN or Swiss Phone Number
extend("nsn_tel", {
    validate(value) {
        return nsn.test(String(value));
    },
    message: (_, values) => i18n.t("invalid NSN or Tel. number", values)
});

// value is valid 3cx key
extend("key3cx", {
    validate(value) {
        return key3cx.test(String(value))
    },
    message: (_, values) => i18n.t("invalid 3cx key", values)
});

// value is valid LOC Record
extend("loc_record", {
    validate(value) {
        return locRecord.test(String(value))
    },
    message: (_, values) => i18n.t("invalid LOC Record", values)
});

// rules="target_max:field_name,100"
extend('min_date', {
    params: ['min', 'other'],
    validate(value, { min, other }) {
        var res = true;
        if (other)
            res = min ? moment(other).isSame(min, "day") || moment(other).isAfter(min) : true;
        else if (value)
            res = min ? moment(value).isSame(min, "day") || moment(value).isAfter(min) : true;
        return res;
    },
    message: function (_, values) {
        // convert data related to store language
        values.min = new Date(values.min).toLocaleDateString(store.state.locale, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
        });
        return i18n.t("The {_field_} field may not be before {min}", values)
    }
});

// set initial language
loadLocale(store.state.locale);

// configure styling
configure({
    bails: false,
    skipOptional: true,
    mode: 'aggressive',
    useConstraintAttrs: true
})

// get object of unresolved (unmached) form errors
function getUnresolvedErrors(error, data = null, history = null) {
    var that = this;
    if (data == null) data = { ...error.data };
    if (history == null) history = {};

    history[this.id] = this;

    // ValidationObserver.refs contains all ValidationProviders with 'vid' as key
    // drop all known 'vid' from error data
    Object.keys(error.data).forEach(function (key) {
        if (that.refs[key]) {
            delete data[key];
            //console.log(">>> delete ", key, " handled by ", that.id);
        }
    });

    // check all child observers ...
    this.observers.forEach(function (obs) {
        if (!history.hasOwnProperty(obs.id)) {
            //console.log(">>> check observer ", obs.id);
            data = obs.getUnresolvedErrors(error, data, history);
        }
    });

    return data;
}
ValidationObserver.prototype.getUnresolvedErrors = getUnresolvedErrors;

// global add components
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

export default function loadLocale(code) {
    return import(`vee-validate/dist/locale/${code.toLowerCase()}.json`)
        .then(locale => {
            localize(code, locale);
        });
}
export {
    email,
    dns,
    nsn,
    isValidNameserver,
    isValidatePhoneNumberRange,
};